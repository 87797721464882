@import "../../../styles/variables.scss";

.title {
  font-weight: 300;
  background-color: #8e3333;
  color: white;
  text-align: center;
}

.detailsCard {
  padding: 1rem;
  overflow: auto;
}

.cardTitle {
  margin-bottom: 0.5rem;
}

.clientDialog {
  width: 600px;
}
