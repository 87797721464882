@import "../../styles/variables.scss";

.card {
  // background-color: #fff;
  // width: 100%;
  // border-radius: 5px;
  // display: flex;
  // flex-flow: column;
  // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.425);
  // position: relative;
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  width: 100%;
  box-shadow: 0 2.5px 3px 0 rgba(0, 0, 0, 0.308);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  font-size: 0.875rem;
  color: black;
}

.chart{
  & p{
    margin-top: 0;
    padding-top: 0;
  }
}
