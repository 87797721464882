.checkBoxWrapper {
    display: inline-block;
    margin-right: 3px;
    height: 100%;
    position: relative;
    width: 36px;
  }
  
  .checkBox {
    padding: 0;
    position: absolute;
    bottom: -6px;
    svg {
      width: 28px;
      height: 28px;
    }
  }