$primary-color: #720000;
$secondary-color: #00c8eb;
$base-color: #f2e8cf;
$dark-color: #242834;

@mixin media-sm {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin transition-ease {
  transition: all 0.3s ease-in-out;
}

@mixin transition-ease-fast {
  transition: all 0.15s ease-in-out;
}

@mixin absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin title {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  & small {
    color: rgba(0, 0, 0, 0.38);
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
  }
  @content;
}

@mixin cardTitle {
  @include title;
  margin-top: 0;
  margin-bottom: 3px;
  min-height: auto;
  & a {
    @include title;
    margin-top: 0.625rem;
    margin-bottom: 0.75rem;
    min-height: auto;
  }
}
