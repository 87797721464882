@import "../../styles/variables.scss";

.cardTitle {
  @include title {
    margin-top: 15px;
    margin-bottom: 0;
    min-height: auto;
    font-size: 1.2rem;
  }
  & a {
    @include title {
      margin-top: 0.625rem;
      margin-bottom: 0.75rem;
      min-height: auto;
    }
  }
}

.actions {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;

  button:nth-of-type(2) {
    margin-left: 1rem;
  }
}
