.layoutWrapper {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background: linear-gradient(to right, rgba(#272727, 0.1), rgba(#272727, 0.1)),
    url("../../images/auth-background.jpg") center bottom no-repeat;
  background-size: cover;
}

.blur {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.521);
  z-index: 1;
}

.main {
  display: grid;
  place-items: center;
  z-index: 2;
}

.footer {
  z-index: 2;
  text-align: center;
  padding: 1rem;

  small {
    background-color: rgba(255, 255, 255, 0.726);
    padding: 0.5rem;
    color: grey;
  }
}
