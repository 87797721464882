* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  font-family: "roboto";
  height: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  background-color: #fafafa;
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-container {
  z-index: 2000 !important;
}

ul {
  list-style-position: inside;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ct-label-point {
  fill: rgba(255, 255, 255, 0.856);
}

.ct-label-bar {
  fill: rgb(71, 71, 71);
}

.ct-bar {
  stroke-width: 8%;
}
