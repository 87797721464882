@import "../../styles/variables.scss";

.cardTitle {
  @include title {
    margin-top: 15px;
    margin-bottom: 0;
    min-height: auto;
    font-size: 1.2rem;
    font-weight: 300;
  }
  & a {
    @include title {
      margin-top: 0.625rem;
      margin-bottom: 0.75rem;
      min-height: auto;
    }
  }
}

.actions {
  width: 9.5em;
}
