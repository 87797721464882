.logo {
  width: 100px;
  background-color: rgba(128, 128, 128, 0.438);
  border: solid 8px white;
  border-radius: 100%;
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.6),
    0 4px 25px 0px hsla(0, 0%, 0%, 0.14), 0 8px 10px -5px rgba(0, 0, 0, 0.12);
  margin-bottom: 1rem;
}

.toolbar {
  padding: 1rem;
  display: grid;
  place-items: center;
  text-align: center;
  width: 100%;
}

.nested {
  padding-left: 32px;
}
