@import "../../../styles/variables.scss";

.cardIcon {
  border-radius: 3px;
  background-color: $primary-color;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
  color: white;
}
