@import "../../styles/variables.scss";

.loginCard {
  max-width: 300px;
  place-items: center;
}

.logo {
  width: 100px;
  background-color: rgba(128, 128, 128, 0.438);
  border: solid 8px white;
  border-radius: 100%;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.6),
    0 4px 25px 0px hsla(0, 0%, 0%, 0.14), 0 8px 10px -5px rgba(0, 0, 0, 0.12);
}

.backArrow {
  position: absolute;
  z-index: 3;
  left: 15px;
  top: 15px;
  color: white;

  svg {
    font-size: 2rem;
  }
}

@include media-md {
  .loginCard {
    max-width: 350px;
  }

  .backArrow {
    left: 40px;
    top: 20px;
  
    svg {
      font-size: 3rem;
    }
  }
}
