@import "../../../styles/variables.scss";

.cardHeader {
  background-color: $primary-color;
  // margin: 0 15px;
  // width: calc(100% - 30px);
  // position: relative;
  // padding: 0.75rem;
  // border-radius: 3px;
  // z-index: 3 !important;
  // margin-top: -20px;
  // margin-bottom: 20px;
  // display: flex;
  // flex-flow: column;
  // place-items: center;
  // color: #fff;
  // box-shadow: 1.5px 1.5px 10px rgba(0, 0, 0, 0.425);
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: none;
  z-index: 3 !important;
  margin: 0 15px;
  padding: 0;
  position: relative;

  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }

  &:not(.cardHeaderIcon) {
    border-radius: 3px;
    margin-top: -20px;
    padding: 15px;
  }
}

.cardHeaderIcon {
  background: transparent;
  box-shadow: none;
  margin: 0 15px;

  & .fab,
  & .fas,
  & .far,
  & .fal,
  & .material-icons {
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 33px;
  }

  & svg {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 33px;
    margin: 5px 4px 0px;
  }
}

.cardHeaderSignUp {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -40px;
  padding: 20px 0;
  width: 90%;
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
}

.cardHeaderStats {
  & .cardheadericon {
    text-align: right;
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 0 !important;
  }

  & svg {
    width: 36px;
    height: 36px;
  }
}
