@import "../../styles/variables.scss";

.loginCard{
    max-width: 300px;
    place-items: center;
}

.logo{
    width: 100px;
    background-color: rgba(128, 128, 128, 0.438);
    border: solid 8px white;
    border-radius: 100%;
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.6),
      0 4px 25px 0px hsla(0, 0%, 0%, 0.14), 0 8px 10px -5px rgba(0, 0, 0, 0.12);;
}

@include media-md{
    .loginCard{
        max-width: 350px;
    }
}