@import "../../../styles/variables.scss";

.cardTitle {
  @include title {
    margin-top: 15px;
    margin-bottom: 0;
    min-height: auto;
    font-size: 1.2rem;
    font-weight: 300;
  }
  display: inline-block;
  & a {
    @include title {
      margin-top: 0.625rem;
      margin-bottom: 0.75rem;
      min-height: auto;
    }
  }
}

.addBtn {
  border-radius: 100%;
  min-width: 0;
  padding: 5px;
  margin-top: 1rem;
}

.closeBtn {
  position: absolute;
  z-index: 2;
  right: 2px;
  top: 2px;
}
